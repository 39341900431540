import Omit from 'lodash/omit'
import { useAppStore } from '~/store/app'

export function useToggleMenu() {
  const { $pinia } = useNuxtApp()
  const appstore = useAppStore($pinia)
  const { setDepositFrom, setOpenWithdrawFrom } = appstore

  const toggleMenu = (className: string): void => {
    document.querySelector('body')?.classList.toggle(className)
  }

  const removeMenu = () => {
    const body = document.querySelector('body')
    const classesToRemove = ['show-menu-account', 'show-menu-deposit', 'show-menu-withdraw']
    if (body) {
      classesToRemove.forEach((className) => {
        body.classList.remove(className)
      })
    }
    setDepositFrom('')
    setOpenWithdrawFrom('')
  }
  return {
    toggleMenuAccount: () => toggleMenu('show-menu-account'),
    toggleMenuDeposit: () => toggleMenu('show-menu-deposit'),
    toggleMenuWithdraw: () => toggleMenu('show-menu-withdraw'),
    removeMenu
  }
}
